<template>
  <v-app>
    <!-- LOGIN FORM -->
    <v-container fluid>
      <!-- HEADER -->
      <!-- <v-alert type="info" class="text-center">
        <span>{{ $t("login.information") }}</span
        ><span style="text-decoration: underline"> Mozilla Firefox</span>,
        <span style="text-decoration: underline">Google Chrome</span>
        {{ $t("login.and") }}
        <span style="text-decoration: underline"> Safari</span>
        <span>{{ $t("login.last")}}</span>
      </v-alert> -->
      <!-- DESKTOP -->
      <div class="d-none d-sm-flex">
        <v-card
          class="pa-9"
          width="400"
          elevation="8"
          style="margin: auto; margin-top: 10%; border-radius: 20px"
        >
          <v-img
            height="75px"
            width="200px"
            src="../assets/logo1.png"
            alt="Default logo"
          ></v-img>
          <v-text-field
            color="#df6464"
            v-model="email"
            :label="$t('login.username')"
            append-icon="mdi-email"
            rounded
            outlined
          ></v-text-field>
          <v-text-field
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            style="margin-bottom: -12px"
            color="#df6464"
            v-model="password"
            :label="$t('login.password')"
            :type="show1 ? 'text' : 'password'"
            rounded
            outlined
            @click:append="show1 = !show1"
            @keyup.enter="loginUser"
          ></v-text-field>
          <!-- <span style="color: grey; font-size: 12px; margin-left: 15px"
            >{{ $t("login.account") }} </span
          ><span
            @click="loginForm = !loginForm"
            style="
              color: #df6464;
              font-size: 13px;
              font-weight: bold;
              cursor: pointer;
            "
            >{{ $t("login.signup") }}</span
          > -->
          <v-btn
            class="pa-6"
            rounded
            color="#df6464"
            width="100%"
            style="color: white"
            @click="loginUser"
            >{{ $t("login.loginButton") }}</v-btn
          >
          <v-card-subtitle class="text-center">
            {{ $t("login.hrd") }} <br />
            <LanguageSwitcher />
          </v-card-subtitle>
        </v-card>
      </div>
      <!-- MOBILE -->
      <div class="d-sm-none">
        <v-img
          height="75px"
          width="200px"
          src="../assets/logo1.png"
          alt="Default logo"
        ></v-img>
        <v-text-field
          color="#df6464"
          v-model="email"
          label="Eメール"
          append-icon="mdi-email"
          rounded
          outlined
        ></v-text-field>
        <v-text-field
          color="#df6464"
          v-model="password"
          label="パスワード"
          append-icon="mdi-lock"
          type="password"
          rounded
          outlined
          @keyup.enter="loginUser"
        ></v-text-field>
        <!-- <span style="color: grey; font-size: 12px; margin-left: 15px"
          >Don't have an account? </span
        ><span
          @click="loginForm = !loginForm"
          style="
            color: #df6464;
            font-size: 13px;
            font-weight: bold;
            cursor: pointer;
          "
          >Sign Up</span
        > -->
        <v-btn
          class="pa-6"
          rounded
          color="#df6464"
          width="100%"
          style="color: white"
          @click="loginUser"
          >ログインする</v-btn
        >
        <v-card-subtitle class="text-center" style="color: grey">
          {{ $t("login.hrd") }} <br />
          <LanguageSwitcher />
        </v-card-subtitle>
      </div>

      <!-- SNACKBAR -->
      <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="2000" top>
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn black text max-width="100" v-bind="attrs" @click="snackbar = false">
            {{ language == "en" ? "CLOSE" : "閉じる" }}
          </v-btn>
        </template>
      </v-snackbar>
      <!-- WAITING MODAL -->
      <v-dialog v-model="waitingDialog" v-if="waitingDialog" persistent max-width="200px">
        <v-card class="pa-3 text-center">
          <b style="font-size: 15px; font-family: Arial, Helvetica, sans-serif">{{
            language == "en" ? "Please Wait..." : "お待ちください..."
          }}</b>
          <v-img src="../assets/loading.gif" width="50" height="50" style="margin: auto">
          </v-img>
        </v-card>
      </v-dialog>
      <!-- LOGIN MODAL -->
      <v-dialog
        v-model="loginDialog"
        v-if="loginDialog"
        persistent
        max-width="200px"
        transition="fade"
      >
        <v-card class="pa-3 text-center">
          <b style="font-size: 15px; font-family: Arial, Helvetica, sans-serif">{{
            $t("alert.loginSuccess")
          }}</b>
          <v-img src="../assets/check.gif" width="120" height="120" style="margin: auto">
          </v-img>
        </v-card>
      </v-dialog>

      <!-- LOGIN FORM -->
      <div class="text-center">
        <v-bottom-sheet v-model="loginForm" inset width="700px">
          <v-sheet height="740" v-scrollbar class="myCalc">
            <v-container>
              <v-row fixed>
                <v-col>
                  <h2>Registration Form</h2>
                  <span class="text--secondary" style="margin-left: 10px"
                    >Fill-up all required fields.</span
                  >
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="registationRow">
                <v-col>
                  <span class="form-title">Employee Code</span
                  ><span class="required">*</span
                  ><span style="color: red; font-size: 12px">(5-Digit ID Number)</span>
                  <v-text-field
                    v-model="EmployeeCode"
                    @keyup="checkUserInformation"
                    color="#e73b3b"
                    counter="5"
                    maxlength="5"
                    :loading="checkUserInput"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="registationRow">
                <v-col>
                  <span class="form-title">FullName</span><span class="required">*</span>
                  <v-text-field v-model="FullName" color="#e73b3b" dense> </v-text-field>
                </v-col>
              </v-row>
              <v-row class="registationRow">
                <v-col>
                  <span class="form-title">Department</span
                  ><span class="required">*</span>
                  <v-text-field v-model="Department" color="#e73b3b" dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="registationRow">
                <v-col>
                  <span class="form-title">Section</span><span class="required">*</span>
                  <v-text-field v-model="Section" color="#e73b3b" dense> </v-text-field>
                </v-col>
              </v-row>
              <v-row class="registationRow">
                <v-col>
                  <span class="form-title">Team</span><span class="required">*</span>
                  <v-text-field v-model="Team" color="#e73b3b" dense> </v-text-field>
                </v-col>
              </v-row>
              <v-row class="registationRow">
                <v-col>
                  <span class="form-title">Position</span><span class="required">*</span>
                  <v-text-field v-model="Position" color="#e73b3b" dense> </v-text-field>
                </v-col>
              </v-row>
              <v-row class="registationRow">
                <v-col>
                  <span class="form-title">Password</span><span class="required">*</span>
                  <v-text-field
                    v-model="userPassword"
                    type="password"
                    color="#e73b3b"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="registationRow">
                <v-col>
                  <span class="form-title">Confirm Password</span
                  ><span class="required">*</span>
                  <v-text-field
                    v-model="confirmPassword"
                    :rules="[passwordMatch]"
                    v-bind:error="checkUserPassword"
                    color="#e73b3b"
                    type="password"
                    dense
                  >
                  </v-text-field>
                </v-col> </v-row
              ><br />
              <v-row class="registationRow">
                <v-col class="mt-n8">
                  <span class="text--secondary ml-16">Include Correction Request?</span
                  ><span class="required">*</span>
                  <v-radio-group v-model="Type" dense class="mt-n0">
                    <v-radio color="#df6464" label="Inquiry Only" value="Inquiry">
                    </v-radio>
                    <v-radio
                      color="#df6464"
                      label="Correction Request Only"
                      value="Correction"
                    >
                    </v-radio>
                    <v-radio color="#df6464" label="Both" value="Both"> </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row style="padding-left: 25%; padding-right: 25%">
                <v-col>
                  <v-btn class="no-uppercase" block @click="clearFormData">
                    <v-icon dark> mdi-minus </v-icon>
                    Clear
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    class="no-uppercase"
                    color="#df6464"
                    block
                    @click.native="registerUser"
                  >
                    <v-icon dark> color="primary" mdi-account-plus </v-icon>
                    Register
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import md5 from "md5";
import axios from "axios";
import moment from "moment";
import LanguageSwitcher from "@/components/Others/LanguageSwitcher";
import objects from "../functions/alert";
// import Swal from "sweetalert2";
// import CorrectionTable from "../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
// import JobKindTable from "../functions/httpRequest/ExternalConnection/JobKindTable";
// import TemplateTable from "../functions/httpRequest/ExternalConnection/TemplateTable";
// import InquiryKindTable from "../functions/httpRequest/ExternalConnection/InquiryKindTable";
import hrdAPI from "../functions/httpRequest/hrdappsAPI/allConnection";
import UsersTable from "../functions/httpRequest/ExternalConnection/UsersTable";
import CorrectionUser from "../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
// import GCPortalAPI from "../functions/httpRequest/OtherAPI/GCPortalAPI.js";
import check from "../functions/autoLogin";
// import axios from "axios";

export default {
  data() {
    return {
      show1: false,
      snackbar: false,
      loginForm: false,
      checkUserInput: false,
      checkUserPassword: false,
      loginDialog: false,
      waitingDialog: false,
      snackbarColor: "",
      snackbarText: "",
      userType: "",
      EmployeeCode: "",
      FullName: "",
      Department: "",
      Section: "",
      Team: "",
      Position: "",
      userPassword: "",
      confirmPassword: "",
      Type: "",
      email: "",
      mozilla: "Firefox Installer",
      chrome: "ChromeSetup ",
      password: "",
      userIPAddress: "",
      registration: {},
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  components: {
    LanguageSwitcher,
  },

  async created() {
    // CALL AUTOLOGIN FUNCTION
    this.CheckAutoLogin();
    // FOR WINDOW RESPONSIVENESS
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    //***************************//
    //  GET USER IP. LOCAL ONLY  //
    //***************************//

    getIP() {
      UsersTable.GetUserIP()
        .then((res) => {
          this.userIPAddress = res.data.split(":")[3];
          if (this.userIPAddress != "10.169.130.89")
            this.$router.push("/InvalidCredencial");
        })
        .catch((err) => console.log(err));
    },
    //***************************//
    //    AUTOLOGIN FUNCTION     //
    //***************************//

    CheckAutoLogin() {
      // CHECK ROUTE QUERY
      if (this.$route.query.index) {
        this.waitingDialog = true;

        // PASS ROUTES QUERY AND GET CALLBACK RESPONSE
        check.Autologin(this.$route.query.index, (info) => {
          // ADD USER INFO TO VUEX
          this.ADD_USER_INFORMATION(info);
          this.waitingDialog = false;

          // CHECK IF DEVELOPMENT OR PRODUCTION MODE
          process.env.NODE_ENV == "development"
            ? window.open(`http://${window.location.host}/composed`, "_self")
            : window.open("https://inquiry.hrd-s.com/composed", "_self")
            ? window.open(`http://${window.location.host}/login`, "_self") //FOR DEVELOPMENT MODE
            : window.open(
                `http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/login`,
                "_self"
              ); //FOR DEVELOPMENT MODE
        });
      }
    },
    //***************************//
    //      LOGIN  FUNCTION      //
    //***************************//
    loginUser() {
      // let info;
      // let rulebookInfo;

      // IF USER LOGIN VIA EMAIL
      if (this.email.includes("@")) {
        // var getInquiryKind = [];
        // var getTemplateId = [];
        // var getJobKindId = [];
        // InquiryKindTable.Get().then((res) => {
        //   for (let i = 0; i < res.data.length; i++) {
        //     const element = res.data[i];
        //     getInquiryKind.push(element.Code);
        //   }
        //   //   // console.log(getInquiryKind);
        // });
        // TemplateTable.Get().then((res) => {
        //   for (let i = 0; i < res.data.length; i++) {
        //     const element = res.data[i];
        //     getTemplateId.push(element.id);
        //   }
        //   //   // console.log(res.data);
        // });
        // JobKindTable.Get().then((res) => {
        //   for (let i = 0; i < res.data.length; i++) {
        //     const element = res.data[i];
        //     getJobKindId.push(element.Code);
        //   }
        //   // console.log(res.data);
        // });

        this.waitingDialog = true;

        const BaseUrlUSERS =
          "https://v7ck8ajvp1.execute-api.us-east-2.amazonaws.com/prod";
        const apiKeyUSERS = {
          headers: { "x-api-key": "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3" },
        };
        axios.get(`${BaseUrlUSERS}/getUserDetails/${this.email}`, apiKeyUSERS).then((res) => {

          
          let getichijo = []
          if(res.data){
            this.waitingDialog = false;
            this.loginDialog = true;
            let encrypted = md5(this.password);

            if (
              this.email == res.data.MailAddress &&
              encrypted == res.data.Password &&
              res.data.hrdInquiry
            ) {
              getichijo.push(res.data)
            } else {
              // Swal.fire({
              //   title: "Not HRD Inquiry user",
              //   icon: "error",
              // });
              this.waitingDialog = false;
            }
          }

          // let getichijo = res.data.filter((rec) => {
          //   this.waitingDialog = false;
          //   this.loginDialog = true;

          //   let encrypted = md5(this.password);
          //   if (
          //     this.email == rec.MailAddress &&
          //     encrypted == rec.Password &&
          //     rec.hrdInquiry
          //   ) {
          //     return rec.UserId;
          //   } else {
          //     Swal.fire({
          //       title: "Not HRD Inquiry user",
          //       icon: "error",
          //     });
          //     this.waitingDialog = false;
          //   }
          // });
          

          // console.log(getichijo, "454");
          if (getichijo.length) {
            setTimeout(() => {
              getichijo[0].FullName = `${getichijo[0].Name}`;
              getichijo[0].empcode = getichijo[0].UserId + "-JP";
              getichijo[0].Email = getichijo[0].MailAddress;
              getichijo[0].UserLevel = "Inquiry";
              getichijo[0].EmployeeCode = getichijo[0].UserId + "-JP";
              getichijo[0].UserType = "Ichijo";
              // getichijo[0].Inquiry = JSON.stringify(getInquiryKind);
              // getichijo[0].Template = JSON.stringify(getTemplateId);
              getichijo[0].Type = "Inquiry";
              getichijo[0].Signature = "";
              this.ADD_USER_INFORMATION(getichijo[0]);

              process.env.NODE_ENV == "development"
                ? this.$router.push("/composed")
                : // window.open(
                //     `http://${window.location.host}/composed`,
                //     "_self"
                //   )
                this.$router.push("/composed")
                ? //window.open("https://inquiry.hrd-s.com/composed", "_self");

                  this.$router.push("/composed")
                : // window.open(
                  //     `http://${window.location.host}/composed`,
                  //     "_self"
                  //   )
                  window.open(
                    `http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/composed`,
                    "_self"
                  );
            }, 1900);
            return false;
          }
          if (!getichijo.length) {
            console.log("ICHIJO");

            if (this.email == "") {
              this.waitingDialog = false;
              this.loginDialog = false;
              this.$toast.error(this.$t("alert.email"), objects.alert);
            } else {
              this.waitingDialog = false;
              this.loginDialog = false;
              this.$toast.error(this.$t("alert.invalid"), objects.alert);
            }
          }
        });

        // GC PORTAL LOGIN
        // GCPortalAPI.getSpecific(this.email).then(async (res) => {
        //   info = res.data;

        //   await GCPortalAPI.getSpecificRulebook(this.email).then(
        //     (res) => (rulebookInfo = res.data)
        //   );
        //   // CHECK IF GC PORTAL AND RULEBOOK API IS EMPTY
        //   if (Object.keys(info).length === 0 && Object.keys(rulebookInfo).length === 0) {
        //     await UsersTable.getSpecificEmail(this.email).then((res) => {
        //       console.log(res.data, "610");
        //       // GET CORRECT USERNAME / PASSWORD
        //       if (
        //         this.email == res.data.Email &&
        //         md5(this.password) == res.data.Password
        //       ) {
        //         // console.log("yeheyyyyy");
        //         //CHECK USER TYPE
        //         res.data.UserType = "Ichijo";
        //         res.data.EmployeeCode = res.data.EmployeeCode + "-GC";
        //         this.waitingDialog = false;
        //         this.loginDialog = true;

        //         setTimeout(() => {
        //           //ADD RECORD IN VUEX AND CHECK IF DEVELOPMENT MODE
        //           this.loginDialog = false;

        //           this.ADD_USER_INFORMATION(res.data);
        //           // console.log(this.ADD_USER_INFORMATION());
        //           process.env.NODE_ENV == "development"
        //             ? window.open(`http://${window.location.host}/composed`, "_self")
        //             : window.open("https://inquiry.hrd-s.com/composed", "_self")
        //             ? window.open(`http://${window.location.host}/composed`, "_self")
        //             : window.open(
        //                 "http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/composed",
        //                 "_self"
        //               );
        //         }, 1900);
        //       }
        //     });
        //     return false;
        //   } else {
        //     // IF GC PORTAL OR RULEBOOK API HAS A RECORD  === 0
        //     if (Object.keys(info).length) {
        //       // IF GC PORTAL API IS EMPTY
        //       rulebookInfo.UserType = "Ichijo";
        //       info = rulebookInfo;
        //     } else info.UserType = "Gc";

        //     info.EmployeeCode = info.UserId + "-GC";
        //     info.FullName = info.Name;
        //     info.EmployeeName = info.Name;
        //     info.Type = "Inquiry";
        //     info.UserType = "Ichijo";
        //     info.Email = info.MailAddress;
        //     info.Inquiry = JSON.stringify(getInquiryKind);
        //     info.Template = JSON.stringify(getTemplateId);
        //     delete info.UserId;
        //     delete info.Name;
        //     delete info.MailAddress;

        //     // CHECK FOR CORRECT USERNAME AND PASSWORD
        //     if (info.Email == this.email && info.Password == md5(this.password)) {
        //       this.waitingDialog = false;
        //       this.loginDialog = true;

        //       // ADD RECORD IN VUEX AND CHECK FOR DEVELOPMENT MODE
        //       setTimeout(() => {
        //         this.loginDialog = false;

        //         this.ADD_USER_INFORMATION(info);
        //         // console.log("yehey");
        //         process.env.NODE_ENV == "development"
        //           ? window.open(`http://${window.location.host}/composed`, "_self")
        //           : window.open("https://inquiry.hrd-s.com/composed", "_self")
        //           ? window.open(`http://${window.location.host}/composed`, "_self")
        //           : window.open(
        //               "http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/composed",
        //               "_self"
        //             );
        //       }, 1900);
        //     }
        //   }
        // });
      } else {
        // alert("YOU ENTER EMPLOYEE NUMBER PLEASE ENTER EMAIL ONLY");
        // return false;
        // IF USER LOGIN VIA ID NUMBER
        this.waitingDialog = true;
        UsersTable.GetSpecificUser(this.email)
          .then((res) => {
            // IF ID NUMBER IS IN THE DATABASE
            if (res.data != "No Data Found!") {
              // CHECK FOR CORRECT USERNAME AND PASSWORD
              if (
                this.email == res.data[0].EmployeeCode &&
                md5(this.password) == res.data[0].Password
              ) {
                this.waitingDialog = false;
                this.loginDialog = true;
                res.data[0].UserType = "Ichijo";

                // INSERT RECORD IN VUEX AND CHECK FOR DEVELOPMENT MODE
                setTimeout(() => {
                  this.loginDialog = false;
                  this.ADD_USER_INFORMATION(res.data[0]);
                  process.env.NODE_ENV == "development"
                    ? window.open(
                        `http://${window.location.host}/${
                          res.data[0].Type == "Correction"
                            ? "undermaintenance"
                            : "composed"
                        }`,
                        "_self"
                      )
                    : window.open(
                        `https://inquiry.hrd-s.com/${
                          res.data[0].Type == "Correction"
                            ? "undermaintenance"
                            : "composed"
                        }`,
                        "_self"
                      )
                    ? window.open(
                        `http://${window.location.host}/${
                          res.data[0].Type == "Correction"
                            ? "undermaintenance"
                            : "composed"
                        }`,
                        "_self"
                      )
                    : window.open(
                        `http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/${
                          res.data[0].Type == "Correction"
                            ? "undermaintenance"
                            : "composed"
                        }`,
                        "_self"
                      );
                }, 1900);
              }
            } else {
              // SOCKET DETECT ONLINE
              CorrectionUser.GetSpecificUser(this.email).then((res) => {
                if (!res.data.length) {
                  this.waitingDialog = false;
                  this.$toast.error(this.$t("alert.invalid"), objects.alert);
                  return;
                }
                //  res.data[0].FullName = `${getUsers[0].FirstName} ${getUsers[0].LastName}`

                this.ADD_USER_INFORMATION(res.data[0].Email);

                const record = res.data[0];
                record.Type = "Correction";
                record.UserType = "Ichijo";
                record.FullName = record.EmployeeName;
                delete record.EmployeeName;
                delete record.To;
                delete record.Group;
                delete record.Job;
                delete record.DateCreated;
                // CHECK FOR CORRECT USERNAME AND PASSWORD
                if (
                  this.email == record.EmployeeCode &&
                  this.password == record.EmployeeCode
                ) {
                  this.waitingDialog = false;
                  this.loginDialog = true;

                  // INSERT RECORD IN VUEX AND CHECK FOR DEVELOPMENT MODE
                  setTimeout(() => {
                    this.loginDialog = false;
                    this.ADD_USER_INFORMATION(record);
                    // this.Count();
                    process.env.NODE_ENV == "development"
                      ? window.open(
                          `http://${window.location.host}/${
                            record.Type == "Correction" ? "correction" : "composed"
                          }`,
                          "_self"
                        )
                      : window.open(
                          `https://inquiry.hrd-s.com/${
                            record.Type == "Correction" ? "correction" : "composed"
                          }`,
                          "_self"
                        )
                      ? window.open(
                          `http://${window.location.host}/${
                            res.data[0].Type == "Correction" ? "correction" : "composed"
                          }`,
                          "_self"
                        )
                      : window.open(
                          `http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/${
                            res.data[0].Type == "Correction" ? "correction" : "composed"
                          }`,
                          "_self"
                        );
                  }, 1900);
                } else if (this.password != record.EmployeeCode) {
                  this.waitingDialog = false;
                  this.$toast.error(this.$t("alert.wrongPass"), objects.alert);
                } else {
                  this.waitingDialog = false;
                  this.$toast.error(this.$t("alert.invalid"), objects.alert);
                }
              });
            }
          })
          .catch(() => {
            if (this.email == "") {
              this.waitingDialog = false;
              this.$toast.error(this.$t("alert.email"), objects.alert);
            } else {
              this.waitingDialog = false;
              this.$toast.error(this.$t("alert.invalid"), objects.alert);
            }
          });
      }
    },

    //******************************//
    //  USER REGISTRATION FUNCTION  //
    //******************************//

    async registerUser() {
      //    CHECK IF USER FILL UP ALL FIELDS
      if (
        this.FullName != "" &&
        this.Department != "" &&
        this.Section != "" &&
        this.Team != "" &&
        this.Position != "" &&
        this.userPassword != "" &&
        this.Type != "" &&
        this.confirmPassword != ""
      ) {
        // CHECK IF PASSWORD AND CONFIRM PASSWORD IS SAME
        if (this.userPassword != this.confirmPassword) {
          return false;
        }
        // GATHER REGISTERED EMPLOYEE
        var EmployeeList = {};
        await UsersTable.GetSpecificUser(this.EmployeeCode).then((res) => {
          EmployeeList = res.data;
        });

        // CHECK IF USER IS ALREADY REGISTERED
        if (EmployeeList != "No Data Found!") {
          this.$toast.error("Employee Already Registered", objects.alert);
          return false;
        }
        // REGISTER USER
        this.registration.EmployeeCode = this.EmployeeCode;
        this.registration.FullName = this.FullName;
        this.registration.Department = this.Department;
        this.registration.Section = this.Section;
        this.registration.Team = this.Team;
        this.registration.Position = this.Position;
        this.registration.Type = this.Type;
        this.registration.Password = md5(this.userPassword);
        this.registration.DateRegistered = moment().format("YYYY-MM-DD HH:mm:ss");

        // INSERT RECORD IN DATABASE
        UsersTable.Post(this.registration)
          .then(() => {
            this.registration = {};
            this.EmployeeCode = "";
            this.FullName = "";
            this.Department = "";
            this.Section = "";
            this.Team = "";
            this.Position = "";
            this.userPassword = "";
            this.confirmPassword = "";
            this.Type = "";
            this.loginForm = false;
            this.snackbar = true;
            this.snackbarColor = "success";
            this.snackbarText = "Register Successfully!";
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarColor = "error";
            this.snackbarText = err;
          });
      } else {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText = "Please Fill-up all fields!";
      }
    },

    //***********************************//
    //  GET USER INFORMATION IN HRD API  //
    //***********************************//

    checkUserInformation() {
      // CHECK FOR VALID LENGTH OF ID NUMBER
      if (this.EmployeeCode.length == 5) {
        hrdAPI.HRDSpecificMasterlist(this.EmployeeCode).then((res) => {
          this.FullName = res.EmployeeName;
          this.Department = res.DepartmentName;
          this.Section = res.SectionName;
          this.Team = res.TeamName;
          this.Position = res.DesignationName;
        });
        this.checkUserInput = false;
      } else if (this.EmployeeCode.length == 0) {
        this.FullName = "";
        this.Department = "";
        this.Section = "";
        this.Team = "";
        this.Position = "";
        this.userPassword = "";
        this.confirmPassword = "";
      } else this.checkUserInput = true;
    },
    //***********************************//
    //    CLEAR FORM IN REGISTRATION     //
    //***********************************//

    clearFormData() {
      this.EmployeeCode = "";
      this.FullName = "";
      this.Department = "";
      this.Section = "";
      this.Team = "";
      this.Position = "";
      this.userPassword = "";
      this.confirmPassword = "";
      this.Type = "";
    },

    //***********************************//
    //     HANDLE RESPONSIVENESS         //
    //***********************************//

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  computed: {
    // LANGUAGE LOCALE
    language() {
      return this.$i18n.locale;
    },
    // CHECK IF PASSWORD IS MATCH
    passwordMatch() {
      if (this.confirmPassword) {
        if (this.userPassword == this.confirmPassword) return true;
        else return "Password doesn't match";
      } else return false;
    },
  },
  watch: {
    // WATCH FOR RETURN VALUE OF passwordMatch() function
    passwordMatch() {
      if (this.passwordMatch) this.checkUserPassword = false;
      else this.checkUserPassword = true;
    },
  },
};
</script>

<style>
.fonts {
  font-family: "Digital";
  float: left;
}
.header {
  background: rgb(223, 100, 100);
  background: linear-gradient(
    90deg,
    rgba(223, 100, 100, 1) 49%,
    rgba(245, 242, 242, 1) 100%
  );
  margin-top: -12px;
  color: white;
}
.form-title {
  color: grey;
}
.required {
  color: red;
}
.registationRow {
  padding-left: 25%;
  padding-right: 25%;
  margin-bottom: -28px;
}
.no-uppercase {
  text-transform: none;
}
.myCalc {
  max-height: calc(140vh - 350px);
}
</style>
